import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setRmtRefresh } from "../../../../actions/session";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";
import { Utility } from "../../../../utility/utility";
import ReviewItem from "./ReviewItem";
import ReviewItemBlank from "./ReviewItemBlank";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  potentialProductImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    // cursor: "pointer",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
export default function DialogRecheck(props) {
  const classes = useStyles();
  const { open, onClose,item} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  
  useEffect(() => {
    // const controller = new AbortController();
    if (open) {
      const fetchData = async () => {
        setIsLoading(true);
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/recheck-data`;
        try {
          const result = await axios.post(
            url,
            {
              product_id: item.product_id,
              product_matched_id: item.product_matched_id
            },
            {
              headers: {
                Authorization: JSON.stringify(store.getState().session.token),
              },
            }
          );
          setProduct({
            sim_name:result.data.data.sim_name,
            original:result.data.data.original,
            potential: result.data.data.potential
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      // Reset product state when dialog is closed
      setProduct({});
    }
    // return () => controller.abort();
  }, [ open,item]);
  
const handleUndo = () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/undo`;
      const postData = {
        product_matched_id: item.product_matched_id,
        product_id: item.product_id,  
        action_taken:item.action_taken,
        project_id:item.project_id      
      };
      // console.log(postData);
      axios
        .post(url, postData, {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        })
        .then(() => {       
          dispatch(setRmtRefresh());
          onClose&&onClose()
        })
        .catch((err) => {
          console.log(err);
        });
      //  onRemove&&onRemove(productMatch.id);
   
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      item={item}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle>
          {item.action_taken === 1 && (<span style={{ fontWeight: 'bold', color: '#00b04f' }}>Matches</span>)}
          {item.action_taken === 0 && (<span style={{ fontWeight: 'bold', color: '#DC004E' }}>Does not match</span>)}
          {item.action_taken === 2 && (<span style={{ fontWeight: 'bold', color: '#2650A2' }}>Skip it</span>)}
          {item.action_taken === 3 && (<span style={{ fontWeight: 'bold',  }}>Similar</span>)}
           <span style={{paddingLeft:"8px"}}>- score: {Utility.roundToNDecimal(item.sim_name,8)}</span>
      </DialogTitle>
        <DialogContent dividers>
          
        <Grid
            className={classes.root}
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {isLoading &&(
              <ReviewItemBlank/>
              )}
            {(!isLoading) &&product && product.original && 
              <ReviewItem
                  key={'original'}
                  productMatch={product.original}
                  groupId={product.original.product_matching_group_id}
                  simSource={'_ve'}
                  // handleGoNext={handleGoNext}
              />
            }
            {isLoading &&(
            <ReviewItemBlank/>
            )}
            { (!isLoading)&&product && product.potential&&             
              <ReviewItem
                key={'potential'}
                productMatch={product.potential}
                groupId={product.original.product_matching_group_id}
                simSource={'_ve'}
              />
            }
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
                    color="secondary" onClick={handleUndo} >Undo</Button>
          <Button variant="contained"
                     onClick={onClose}>Cancel</Button>
        </DialogActions>
       
    </Dialog>
  );
}
